<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="logo"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Inicie sesión en su cuenta
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Usuario"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuario"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="nombre de usuario"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Recuperar contraseña</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recuerdame
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Ingresar
              </b-button>
            </b-form>
          </validation-observer>
          <!-- social buttons -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import axios from 'axios'
import qs from 'qs'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      logo: require('@/assets/images/logo/Imagotipo.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/Logotipo.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      const data = qs.stringify({
        client_id: 'epa-client',
        grant_type: 'password',
        username: 'admin',
        password: 'admin',
      })
      // console.log('qs magic:', data)
      const config = {
        method: 'post',
        url: `/auth/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data,
      }

      axios(config).then(res => {
        const userData = res.data
        const ability = [
          {
            action: 'read',
            subject: 'home',
          },
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'Dependencia',
          },
          {
            action: 'read',
            subject: 'Menu',
          },
          {
            action: 'read',
            subject: 'MenuCustom',
          },
          {
            action: 'read',
            subject: 'cargos',
          },
          {
            action: 'read',
            subject: 'usuarios',
          },
          {
            action: 'read',
            subject: 'usuariogrupo',
          },
          {
            action: 'read',
            subject: 'pais',
          },
          {
            action: 'read',
            subject: 'departamento',
          },
          {
            action: 'read',
            subject: 'ciudad',
          },
          {
            action: 'read',
            subject: 'menugrupo',
          },
          {
            action: 'read',
            subject: 'Secuencia',
          },
          {
            action: 'read',
            subject: 'Grupo',
          },
          {
            action: 'read',
            subject: 'GrupoEmpresa',
          },
          {
            action: 'read',
            subject: 'menucustomgrupo',
          },
          {
            action: 'read',
            subject: 'actividad',
          },
          {
            action: 'read',
            subject: 'UsuarioEmpresa',
          },
          {
            action: 'read',
            subject: 'Empresa',
          },
          {
            action: 'read',
            subject: 'Causa',
          },
          {
            action: 'read',
            subject: 'EstadoCausal',
          },
          {
            action: 'read',
            subject: 'CausActividad',
          },
          {
            action: 'read',
            subject: 'ActividadTipoConciliacion',
          },
          {
            action: 'read',
            subject: 'workflow',
          },
          {
            action: 'read',
            subject: 'DiagramaEjecucion',
          },
          {
            action: 'read',
            subject: 'Accaus',
          },
          {
            action: 'read',
            subject: 'prueba',
          },
        ]
        userData.ability = ability
        this.$ability.update(ability)
        localStorage.userData = JSON.stringify(userData)
        localStorage.access_token = res.data.access_token
        localStorage.expires_in = res.data.expires_in
        localStorage.refresh_expires_in = res.data.refresh_expires_in
        localStorage.scope = res.data.scope
        localStorage.session_state = res.data.session_state
        localStorage.token_type = res.data.token_type
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
